// React
import React, { useState, useEffect, useRef } from "react";
// Third party
import ReactQuill, { Quill } from "react-quill";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";
import MagicUrl from "quill-magic-url";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  ClassicEditor,
  AccessibilityHelp,
  Autoformat,
  AutoImage,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  Bold,
  CloudServices,
  Code,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  Markdown,
  MediaEmbed,
  PageBreak,
  Paragraph,
  PasteFromMarkdownExperimental,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextPartLanguage,
  TextTransformation,
  Title,
  Underline,
  Undo,
  HtmlEmbed,
  BlockQuote,
  IndentBlock,
  Indent,
  ListProperties,
  TodoList,
  AutoLink,
  CodeBlock,
  HorizontalLine,
  List,
} from "ckeditor5";
import { ExportPdf, ExportWord, ImportWord } from "ckeditor5-premium-features";

import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";
// Styles
import "react-quill/dist/quill.snow.css";
import "../../App.css";

Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

interface EditorProps {
  formik: any;
  error?: string[];
  required?: boolean;
  id: string;
  name: string;
  label?: string;
  isDynamic?: boolean;
  value?: any;
  quillRef: React.RefObject<ReactQuill>;
  onEditorRef?: any;
  serverError?: any;
}

const colors = [
  {
    color: "hsl(0, 0%, 0%)",
    label: "Black",
  },
  {
    color: "hsl(0, 0%, 30%)",
    label: "Dim grey",
  },
  {
    color: "hsl(0, 0%, 60%)",
    label: "Grey",
  },
  {
    color: "hsl(0, 0%, 90%)",
    label: "Light grey",
  },
  {
    color: "hsl(0, 0%, 100%)",
    label: "White",
    hasBorder: true,
  },
  {
    color: "hsl(0, 75%, 60%)",
    label: "Red",
  },
  {
    color: "hsl(30, 75%, 60%)",
    label: "Orange",
  },
  {
    color: "hsl(60, 75%, 60%)",
    label: "Yellow",
  },
  {
    color: "hsl(90, 75%, 60%)",
    label: "Light green",
  },
  {
    color: "#006400",
    label: "Green",
  },
  {
    color: "#4ce699",
    label: "Aquamarine",
  },
  {
    color: "#4ce6e6",
    label: "Turquoise",
  },
  {
    color: "#4c99e6",
    label: "Light blue",
  },
  {
    color: "#4c4ce6",
    label: "Blue",
  },
  {
    color: "#994ce6",
    label: "Purple",
  },
];

const TextEditor: React.FC<EditorProps> = ({
  formik,
  error,
  name,
  id,
  label,
  isDynamic,
  value,
  quillRef,
  onEditorRef,
  serverError,
}) => {
  const [highlightMode, setHighlightMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState("ckeditor");

  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    if (!editor) return;

    const applyHighlight = () => {
      const selection = editor.getSelection();
      if (selection) {
        editor.format("background", "yellow");
      }
    };
    if (highlightMode) {
      editor.on("selection-change", applyHighlight);
    } else {
      editor.off("selection-change", applyHighlight);
    }
    return () => {
      editor.off("selection-change", applyHighlight);
    };
  }, [highlightMode, quillRef]);

  var modules = {
    imageActions: {},
    imageFormats: {},
    magicUrl: true,
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "code",
        "subscript",
        "superscript",
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: colors,
        },
        {
          background: colors,
        },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const styles = {
    fontFamily: "Times New Roman",
    fontSize: "16px",
    paddingBottom: "0px",
  };

  var formats = [
    "header",
    "height",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "strikethrough",
    "blockquote",
    "list",
    "color",
    "background",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
    "float",
  ];

  const LICENSE_KEY =
    "TUEvcENTTFo5MFdWZGZoM2tWejRaQkxTKytibFZ4RnVkSlhIc3J6OWJYa2NrTTRHekNEam1qYXRNMUVQL0E9PS1NakF5TkRBNE1UZz0=";

  const editorContainerRef = useRef(null);
  const editorRef = useRef<any>(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const editorConfig: any = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "link",
        "insertTable",
        "highlight",
        "blockQuote",
        "codeBlock",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      AccessibilityHelp,
      Autoformat,
      AutoImage,
      Autosave,
      BalloonToolbar,
      Base64UploadAdapter,
      Bold,
      CloudServices,
      Code,
      Essentials,
      ExportPdf,
      ExportWord,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      Highlight,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      ImportWord,
      Italic,
      Link,
      LinkImage,
      Markdown,
      MediaEmbed,
      PageBreak,
      Paragraph,
      PasteFromMarkdownExperimental,
      PasteFromOffice,
      RemoveFormat,
      SelectAll,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      Title,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextPartLanguage,
      TextTransformation,
      Title,
      Underline,
      Undo,
      HtmlEmbed,
      BlockQuote,
      IndentBlock,
      Indent,
      ListProperties,
      TodoList,
      AutoLink,
      CodeBlock,
      HorizontalLine,
      List,
    ],
    balloonToolbar: [
      "bold",
      "italic",
      "|",
      "Highlight",
      "link",
      "strikethrough",
    ],
    exportPdf: {
      stylesheets: [
        /* This path should point to application stylesheets. */
        /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-pdf.html */
        "./App.css",
        /* Export PDF needs access to stylesheets that style the content. */
        "https://cdn.ckeditor.com/ckeditor5/42.0.1/ckeditor5.css",
        "https://cdn.ckeditor.com/ckeditor5-premium-features/42.0.1/ckeditor5-premium-features.css",
      ],
      fileName: "export-pdf-demo.pdf",
      converterOptions: {
        format: "Tabloid",
        margin_top: "20mm",
        margin_bottom: "20mm",
        margin_right: "24mm",
        margin_left: "24mm",
        page_orientation: "portrait",
      },
    },
    exportWord: {
      stylesheets: [
        /* This path should point to application stylesheets. */
        /* See: https://ckeditor.com/docs/ckeditor5/latest/features/converters/export-word.html */
        "./App.css",
        /* Export Word needs access to stylesheets that style the content. */
        "https://cdn.ckeditor.com/ckeditor5/42.0.1/ckeditor5.css",
        "https://cdn.ckeditor.com/ckeditor5-premium-features/42.0.1/ckeditor5-premium-features.css",
      ],
      fileName: "export-word-demo.docx",
      converterOptions: {
        format: "Tabloid",
        margin_top: "20mm",
        margin_bottom: "20mm",
        margin_right: "24mm",
        margin_left: "24mm",
        orientation: "portrait",
      },
    },
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    highlight: {
      options: [
        {
          model: "redMarker",
          class: "marker-red",
          title: "Red marker",
          color: "var(--ck-highlight-pen-red)",
          type: "marker",
        },
        {
          model: "yellowMarker",
          class: "marker-yellow",
          title: "Yellow Marker",
          color: "var(--ck-highlight-marker-yellow)",
          type: "marker",
        },
        {
          model: "greenMarker",
          class: "marker-green",
          title: "Green marker",
          color: "var(--ck-highlight-marker-green)",
          type: "marker",
        },
        {
          model: "pinkMarker",
          class: "marker-pink",
          title: "Pink marker",
          color: "var(--ck-highlight-marker-pink)",
          type: "marker",
        },
        {
          model: "blueMarker",
          class: "marker-blue",
          title: "Blue marker",
          color: "var(--ck-highlight-marker-blue)",
          type: "marker",
        },
      ],
    },
    fontColor: {
      colors,
    },
    fontBackgroundColor: {
      colors,
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    initialData: "",
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    menuBar: {
      isVisible: true,
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    removePlugins: ["Markdown", "Title"],
  };

  return (
    <React.Fragment>
      <div className="mt-[30px]">
        <div className="flex gap-3">
          {/* <button
            className={`rounded-md py-2 px-4 border border-[#C3C1DF] ${selectedTab === "legacy" ? "border-2 border-[#C3C1DF]" : ""
              }`}
            onClick={() => setSelectedTab("legacy")}
            type="button"
          >
            Legacy Editor
          </button> */}
        </div>
        <div className="mt-4">
          <div>
            <div className="flex justify-between items-center">
              <div>
                <label
                  htmlFor={id}
                  className="text-base text-medium mb-2 flex gap-1"
                >
                  {label}
                </label>
              </div>
              <div className="tooltip2" id={`tooltip-${id}`}>
                <div
                  className={`bg-[#fff]  bottom-9   text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50 tooltiptext2 w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border cursor-pointer dark:before:border-t-primary-dark ${
                    error || serverError
                      ? "border-[#DB1A21] after:border-transparent after:border-t-[#DB1A21] before:top-[97%]"
                      : "border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]"
                  }`}
                >
                  {error
                    ? error || serverError
                    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  className="dark:brightness-[4]"
                >
                  <path
                    d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
                    fill={` ${error ? "#DB1A21" : "#1E87F0"}`}
                  />
                  <path
                    d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
                    fill={` ${error ? "#DB1A21" : "#1E87F0"}`}
                  />
                </svg>{" "}
              </div>
            </div>
            {/* {selectedTab === "legacy" && (
              <div className="relative z-0 h-[400px] overflow-hidden">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleHighlightMode();
                  }}
                  className={`absolute z-10 top-4 left-[38rem] ${highlightMode ? "bg-[#f2ff5f]" : "bg-primary-light"
                    }`}
                >
                  <img src={pencilOpen} alt="" />
                </button>
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  className="dark:placeholder:text-white h-full"
                  id={name}
                  modules={modules}
                  formats={formats}
                  value={isDynamic ? value || "" : formik?.values[name] || ""}
                  placeholder="write your content ...."
                  onChange={(value) => formik.setFieldValue(name, value)}
                  style={{
                    borderLeft: `1px solid ${error ? "#DB1A21" : "#C3C1DF"}`,
                    borderRight: `1px solid ${error ? "#DB1A21" : "#C3C1DF"}`,
                    borderBottom: `1px solid ${error ? "#DB1A21" : "#C3C1DF"}`,
                    borderTop: `1px solid ${error ? "#DB1A21" : "transparent"}`,
                    borderRadius: "20px",
                    height: "100%",
                    ...styles,
                  }}
                ></ReactQuill>
              </div>
            )} */}
            {selectedTab === "ckeditor" && (
              <div>
                <div>
                  <div>
                    <style>
                      {`
            .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
              border-color: ${error ? "#DB1A21" : "#C4C1DE"} !important;
               border-top-right-radius: 20px !important;
                border-top-left-radius : 20px !important;
            }
            .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content .ck-menu-bar{
                border-top-right-radius: 20px !important;
                border-top-left-radius : 20px !important;
                border-color: #C4C1DE !important;

            }
          .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
            border-color: ${error ? "#DB1A21" : "#C4C1DE"} !important;
            border-top-color: #C4C1DE !important;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius : 20px;
            min-height: 200px !important;
          }
          .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
            box-shadow: none !important;
            border-color: ${error ? "#DB1A21" : "#C4C1DE"} !important;
            border-top-color: #C4C1DE !important;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius : 20px;
            min-height: 200px !important;
          }
          .ck.ck-toolbar {
            border-color: ${error ? "#DB1A21" : "#C4C1DE"} !important;
          }
        `}
                    </style>
                    <div className="main-container">
                      <div
                        className="editor-container editor-container_classic-editor"
                        ref={editorContainerRef}
                      >
                        <div className="editor-container__editor">
                          <div ref={editorRef}>
                            {isLayoutReady && (
                              <CKEditor
                                editor={ClassicEditor}
                                config={editorConfig}
                                data={
                                  isDynamic
                                    ? value || ""
                                    : formik?.values[name] || ""
                                }
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  formik.setFieldValue(name, data);
                                  onEditorRef({ keyName: name, ref: data });
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TextEditor;

function configUpdateAlert(config: any) {
  if ((configUpdateAlert as any).configUpdateAlertShown) {
    return;
  }

  const isModifiedByUser = (
    currentValue: string | undefined,
    forbiddenValue: string
  ) => {
    if (currentValue === forbiddenValue) {
      return false;
    }

    return currentValue !== undefined;
  };

  const valuesToUpdate = [];

  (configUpdateAlert as any).configUpdateAlertShown = true;

  if (!isModifiedByUser(config.licenseKey, "<YOUR_LICENSE_KEY>")) {
    valuesToUpdate.push("LICENSE_KEY");
  }

  if (valuesToUpdate.length) {
    window.alert(
      [
        "Please update the following values in your editor config",
        "in order to receive full access to the Premium Features:",
        "",
        ...valuesToUpdate.map((value) => ` - ${value}`),
      ].join("\n")
    );
  }
}
